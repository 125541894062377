
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { notificationsOutline, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonList, IonItem, IonLabel, IonIcon,
        IonThumbnail, IonButtons, IonButton, IonBadge,
        IonSkeletonText, IonChip } from '@ionic/vue';
import NotificationItem from "@/components/NotificationItem.vue";

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

export default {
  name: 'NotificationListPage',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonList, IonItem, IonLabel, IonIcon,
                IonThumbnail, IonButtons, IonButton, IonBadge,
                IonSkeletonText, IonChip, NotificationItem, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { getRelativeDate } = utils();

    // 1. declare state variables (ref to make them reactive)
    const currUser = computed(() => store.state.user);
    const loading = computed(() => store.state.loadingNotifications);
    const allNotifications = computed(() => store.getters.notificationsRelatedToUser);
    const projectsWithNotifications = computed(() => store.getters.projectsWithNotifications);

    const selectedProject = ref("all");

    // methods or filters
    const setSelectedProject = (project = 'all') => {
      selectedProject.value = project;
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, setSelectedProject, getRelativeDate,

      // icons
      notificationsOutline,

      // variables
      loading, allNotifications, projectsWithNotifications, selectedProject,
      currUser,
    }
  }
}
